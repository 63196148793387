import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import {
  Person as PersonIcon,
  Message as MessageIcon,
  ExitToApp as LogoutIcon,
  Send as SendIcon,
  Group as GroupIcon,
  Circle as CircleIcon,
} from '@mui/icons-material';

interface User {
  username: string;
}

const Panel = () => {
  const [onlineUsers, setOnlineUsers] = useState<string[]>([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [status, setStatus] = useState<{type: 'success' | 'error' | ''; message: string}>({
    type: '',
    message: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if (!isAuthenticated) {
      navigate('/');
      return;
    }

    fetchOnlineUsers();
    fetchUsers();

    const interval = setInterval(fetchOnlineUsers, 5000);
    return () => clearInterval(interval);
  }, [navigate]);

  const fetchOnlineUsers = async () => {
    try {
      const response = await fetch('http://54.247.212.213:5000/api/online-users');
      const data = await response.json();
      setOnlineUsers(data.online_users);
    } catch (error) {
      console.error('Error fetching online users:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch('http://54.247.212.213:5000/api/users');
      const data = await response.json();
      if (Array.isArray(data)) {
        setUsers(data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setStatus({
        type: 'error',
        message: 'Kullanıcılar yüklenirken hata oluştu'
      });
    }
  };

  const handleSendMessage = async () => {
    if (!selectedUser || !message) {
      setStatus({
        type: 'error',
        message: 'Lütfen kullanıcı ve mesaj seçin'
      });
      return;
    }

    setIsLoading(true);
    // Mesajı kaydet ve input'u temizle
    const messageToSend = message;
    setMessage('');
    
    // 500ms sonra butonu aktif et
    setTimeout(() => {
      setIsLoading(false);
    }, 500);

    try {
      const response = await fetch('http://54.247.212.213:5000/api/send-message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: selectedUser,
          message: messageToSend,
        }),
      });

      if (response.ok) {
        setStatus({
          type: 'success',
          message: 'Mesaj başarıyla gönderildi'
        });
      } else {
        const errorData = await response.json();
        setStatus({
          type: 'error',
          message: errorData.error || 'Mesaj gönderilirken hata oluştu'
        });
        // Hata durumunda mesajı geri yükle
        setMessage(messageToSend);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus({
        type: 'error',
        message: 'Mesaj gönderilirken hata oluştu'
      });
      // Hata durumunda mesajı geri yükle
      setMessage(messageToSend);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated');
    navigate('/');
  };

  return (
    <Box sx={{ flexGrow: 1, bgcolor: 'background.default', minHeight: '100vh' }}>
      {/* Header */}
      <AppBar position="static" elevation={0} sx={{ mb: 3, backdropFilter: 'blur(20px)', backgroundColor: 'rgba(26, 32, 39, 0.8)' }}>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', fontWeight: 600 }}>
            <MessageIcon sx={{ mr: 1, color: 'primary.main' }} />
            Kick Chat Dashboard
          </Typography>
          <IconButton 
            color="primary" 
            onClick={handleLogout}
            sx={{ 
              '&:hover': { 
                backgroundColor: 'rgba(33, 150, 243, 0.08)' 
              }
            }}
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl">
        <Grid container spacing={3}>
          {/* Online Users Card */}
          <Grid item xs={12} md={3}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(76, 175, 80, 0.1)', color: 'success.main' }}>
                    <GroupIcon />
                  </Avatar>
                }
                title={
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Online Users
                  </Typography>
                }
                subheader={
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {onlineUsers.length} users online
                  </Typography>
                }
              />
              <Divider sx={{ opacity: 0.1 }} />
              <CardContent sx={{ maxHeight: 400, overflow: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}>
                <List>
                  {onlineUsers.map((username) => (
                    <ListItemButton
                      key={username}
                      sx={{
                        borderRadius: 1,
                        mb: 1,
                        '&:hover': { 
                          bgcolor: 'rgba(255, 255, 255, 0.05)',
                          transform: 'translateY(-1px)',
                          transition: 'all 0.2s'
                        },
                        '&.Mui-selected': {
                          bgcolor: 'rgba(33, 150, 243, 0.08)',
                          '&:hover': {
                            bgcolor: 'rgba(33, 150, 243, 0.12)',
                          },
                        },
                      }}
                      onClick={() => setSelectedUser(username)}
                      selected={selectedUser === username}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: 'rgba(76, 175, 80, 0.1)', color: 'success.main' }}>
                          <PersonIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText 
                        primary={username}
                        secondary={
                          <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircleIcon sx={{ color: 'success.main', fontSize: 12, mr: 0.5 }} />
                            Online
                          </Box>
                        }
                      />
                    </ListItemButton>
                  ))}
                  {onlineUsers.length === 0 && (
                    <ListItem>
                      <ListItemText 
                        primary="No online users"
                        sx={{ color: 'text.secondary', fontStyle: 'italic' }}
                      />
                    </ListItem>
                  )}
                </List>
              </CardContent>
            </Card>
          </Grid>

          {/* Message Sending Card */}
          <Grid item xs={12} md={4}>
            <Card sx={{ boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(33, 150, 243, 0.1)', color: 'primary.main' }}>
                    <SendIcon />
                  </Avatar>
                }
                title={
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Send Message
                  </Typography>
                }
                subheader={
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Select a user and type your message
                  </Typography>
                }
              />
              <Divider sx={{ opacity: 0.1 }} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e.target.value)}
                      disabled={isLoading}
                      displayEmpty
                      placeholder="Select a user"
                      sx={{
                        borderRadius: 2,
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(255, 255, 255, 0.1)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'primary.main',
                        },
                      }}
                    >
                      {/* Online Users */}
                      {onlineUsers.map((username) => (
                        <MenuItem 
                          key={`online-${username}`} 
                          value={username}
                          sx={{
                            borderRadius: 1,
                            mx: 1,
                            my: 0.5,
                            '&:hover': {
                              bgcolor: 'rgba(255, 255, 255, 0.05)',
                            },
                            '&.Mui-selected': {
                              bgcolor: 'rgba(33, 150, 243, 0.08)',
                              '&:hover': {
                                bgcolor: 'rgba(33, 150, 243, 0.12)',
                              },
                            },
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <CircleIcon sx={{ color: 'success.main', fontSize: 12, mr: 1 }} />
                            {username}
                          </Box>
                        </MenuItem>
                      ))}
                      
                      {/* Offline Users */}
                      {users
                        .filter(user => !onlineUsers.includes(user.username))
                        .map((user) => (
                          <MenuItem 
                            key={`all-${user.username}`} 
                            value={user.username}
                            sx={{
                              borderRadius: 1,
                              mx: 1,
                              my: 0.5,
                              '&:hover': {
                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                              },
                              '&.Mui-selected': {
                                bgcolor: 'rgba(33, 150, 243, 0.08)',
                                '&:hover': {
                                  bgcolor: 'rgba(33, 150, 243, 0.12)',
                                },
                              },
                            }}
                          >
                            {user.username}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      label="Message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          handleSendMessage();
                        }
                      }}
                      disabled={isLoading}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                          '& fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.1)',
                          },
                          '&:hover fieldset': {
                            borderColor: 'rgba(255, 255, 255, 0.2)',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: 'primary.main',
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSendMessage}
                      disabled={isLoading || !selectedUser}
                      startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <SendIcon />}
                      sx={{
                        height: 48,
                        borderRadius: 2,
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 500,
                        boxShadow: 'none',
                        '&:hover': {
                          boxShadow: 'none',
                          bgcolor: 'primary.dark',
                        },
                      }}
                    >
                      {isLoading ? 'Sending...' : 'Send Message'}
                    </Button>
                  </Grid>
                  {status.message && (
                    <Grid item xs={12}>
                      <Alert 
                        severity={status.type || 'info'}
                        sx={{
                          borderRadius: 2,
                          '& .MuiAlert-icon': {
                            color: status.type === 'success' ? 'success.main' : 'error.main',
                          },
                        }}
                      >
                        {status.message}
                      </Alert>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Kick Chat Widget */}
          <Grid item xs={12} md={5}>
            <Card sx={{ height: '100%', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <CardHeader
                avatar={
                  <Avatar sx={{ bgcolor: 'rgba(33, 150, 243, 0.1)', color: 'primary.main' }}>
                    <MessageIcon />
                  </Avatar>
                }
                title={
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Live Chat
                  </Typography>
                }
              />
              <Divider sx={{ opacity: 0.1 }} />
              <CardContent sx={{ height: 'calc(100vh - 250px)', p: '12px !important' }}>
                <Box
                  component="iframe"
                  src="https://botrix.live/widgets/chat/?bid=46dAjegKRLY7cXKsB1IoxQ&bots=false&emojis=false&hideCommands=false&hideMessages=true&hideMessagesSeconds=10&fontSize=18&trovo=true&twitch=true&youtube=true&kick=true&livespace=true&platformIcon=false&theme=bubbles"
                  sx={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: 1,
                    bgcolor: 'background.paper'
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Panel;
